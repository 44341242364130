import React, { useState } from "react";
import { DateUtils } from '../../utils/date/date'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect } from "react";
import { getConflict, getIncidentCount, getLocation, getPrejudice } from "../../api/user/user.service";
import Loader from "../../component/Loader";
import html2canvas from "html2canvas";
import jsPDF from "jspdf"
import moment from "moment";
import Swal from "sweetalert2";



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: '',
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                precision: 0
            }
        }
    }

};

export default function Trends() {

    const intialIncidentsData = Object.freeze({ reportType: "", data: '', startOfIncident: "", endOfIncident: "" })
    const [incidentsData, updateIncidentsData] = React.useState(intialIncidentsData);
    const [startDefaultDate, setStartDefaultDate] = useState();
    const [endDefaultDate, setEndDefaultDate] = useState();
    const [mainDiv, setMainDiv] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [chartTitle, setChartTitle] = useState();
    const [dropdownMenuForData, setDropdownMenuForData] = useState(false)
    const optionsForPrejudice = ["5 Most Reported Prejudice Types", "Age", "Appearance", "Disability", "Dislike of men", "Dislike of women", "Facial disfiguration", "Gender identity", "Hair colour", "Homophobia", "Hygiene", "Mental Health", "Neurodiversity", "Pregnancy", "Race / Racism", "Religion / Religious beliefs", "Sexism", "Sexual orientation", "Transphobia", "Other"]
    const optionsForConflicts = ["5 Most Reported Conflict Types", "Don't know", "Extortion", "Extremist Views", "Family insult", "Intimidation", "Physical", "Preferential Treatment", "Dissatisfaction with process", "Do not understand process", "Process is not being followed", "Process too rigid", "Process is unmanageable", "Lack of equitable equipment", "Pulling Rank", "Seniority of time in Organisation", "Social Isolation", "Facebook", "Youtube", "WhatsApp", "Messenger", "Instagram", "TikTok", "Pinterest", "Tumblr", "Reddit", "Twitter", "Linkedin", "Snapchat", "Viber", "Discord", "Vimeo", "Telegram", "Line", "Twitch", "Medium", "Rivalry", "Theft", "Verbal", "Other"]
    const optionsForLocations = ["5 Most Reported Locations", "Unit", "Regional Office", "Event Operations", "County Operations", "Ambulance Operations", "Other"]
    const [optionsForData, setOptionsForData] = useState([])
    const [chartAllData, setchartAllData] = useState({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                borderColor: '#D87574',
                backgroundColor: '#D87574',
            },
        ],
    })

    const colors = [
        "#D87574", // pink
        "#f5a24f", // orange
        "#3593c6", // LIGHT BLUE
        "#6360a0", // purple
        "#43b89a", // cyan
        "#0d394e"  // blue
    ];

    const handleChangeIncidentsData = (e) => {
        updateIncidentsData({
            ...incidentsData,
            [e.target.name]: e.target.value
        });
        if (e.target.name == 'startOfIncident') {
            setStartDefaultDate(DateUtils.reduceMonths(new Date(e.target.value), 0));
        } else if (e.target.name == 'endOfIncident') {
            setEndDefaultDate(DateUtils.reduceMonths(new Date(e.target.value), 0));
        } else if (e.target.value == 'Prejudice') {
            setDropdownMenuForData(true)
            setOptionsForData([...optionsForPrejudice])
        } else if (e.target.value == 'Conflict') {
            setDropdownMenuForData(true)
            setOptionsForData([...optionsForConflicts])
        } else if (e.target.value == 'Location') {
            setDropdownMenuForData(true)
            setOptionsForData([...optionsForLocations])
        }
        else if (e.target.value == 'Incidents') {
            setDropdownMenuForData(false)
            setOptionsForData([])
        }
    }

    const changeDates = (e) => {
        if (e.target.value) {

            console.log(DateUtils.reduceMonths(new Date(), e.target.value));
            setStartDefaultDate(DateUtils.reduceMonths(new Date(), e.target.value));
            setEndDefaultDate(DateUtils.reduceMonths(new Date(), 0));
            updateIncidentsData({
                ...incidentsData,
                ["startOfIncident"]: DateUtils.reduceMonths(new Date(), e.target.value),
                ["endOfIncident"]: DateUtils.reduceMonths(new Date(), 0),
            });
        }
    }
    const sortFunction = (a, b) => {
        return b[0] - a[0];
    }

    const getMonths = (sDate, eDate) => {
        var startDate = moment(sDate);
        var endDate = moment(eDate);
        if (endDate.isBefore(startDate)) {
            return Swal.fire({
                text: "End date must be greated than start date.",
            })

        }

        while (startDate.isSameOrBefore(endDate)) {
            result.push(startDate.format("MMM-YYYY"));
            startDate.add(1, 'month');
        }
        return result
    }

    var result = [];

    const reportGenerate = () => {
        var startDate = new Date(incidentsData.startOfIncident);
        var endDate = new Date(incidentsData.endOfIncident);


        let data = [];
        let labels = [];

        if (incidentsData.reportType == '') return Swal.fire({
            text: 'Please Select Report Type',
        })

        if (incidentsData.startOfIncident == '') return Swal.fire({
            text: 'Please Enter Date or Select Date Range',
        })

        if (incidentsData.endOfIncident == '') return Swal.fire({
            text: 'Please Enter Date or Select Date Range',
        })
        setMainDiv(false)
        setChartTitle(incidentsData.reportType)
        setIsLoading(true)

        if (incidentsData.reportType == 'Prejudice') {
            getPrejudice(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    console.log(labels);
                    console.log("====================");
                    console.log(incidentsData.data);
                    let months = getMonths(startDate, endDate);
                    console.log(months);

                    if (incidentsData.data == '5 Most Reported Prejudice Types') {
                        let tempArray = [];
                        for (let i = 0; i < response.data.length; i++) {
                            let counter = 0;
                            for (let j = 0; j < response.data[i].data.length; j++) {
                                counter += response.data[i].data[j].count
                            }
                            tempArray.push([counter, response.data[i].type])
                        }
                        tempArray = tempArray.sort(sortFunction);
                        let datasetForMultiLineChart = [];
                        for (let k = 0; k < 5; k++) {
                            let dataForMulti = []
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].type == tempArray[k][1]) {
                                    for (let j = 0; j < months.length; j++) {
                                        dataForMulti.push(0)

                                        for (let q = 0; q < response.data[i].data.length; q++) {
                                            if (response.data[i].data[q].monthYear == months[j]) {
                                                dataForMulti[j] = response.data[i].data[q].count;
                                                break;
                                            }

                                        }
                                    }

                                    datasetForMultiLineChart.push({
                                        label: tempArray[k][1],
                                        data: dataForMulti,
                                        // borderColor: "#D87574",
                                        borderColor: colors[k],
                                        // backgroundColor:"#2c2c2c",
                                        backgroundColor: colors[k],
                                        tension: 0.2
                                    })

                                }
                            }

                        }
                        console.log(datasetForMultiLineChart);
                        setchartAllData({
                            labels: months,
                            datasets: datasetForMultiLineChart,
                        });


                    } else {

                        for (let i = 0; i < response.data.length; i++) {
                            console.log(response.data[i].type);
                            if (response.data[i].type == incidentsData.data) {
                                console.log(response.data[i]);
                                for (let j = 0; j < response.data[i].data.length; j++) {
                                    console.log(response.data[i].data[j])
                                    labels.push(response.data[i].data[j].monthYear)
                                    data.push(response.data[i].data[j].count)
                                }
                                break
                            }
                        }
                        setchartAllData({
                            labels,
                            datasets: [
                                {
                                    label: incidentsData.data,
                                    data: data,
                                    borderColor: '#D87574',
                                    backgroundColor: '#D87574',
                                    tension: 0.2
                                },
                            ],
                        });
                    }

                    setIsLoading(false)
                });
        } else if (incidentsData.reportType == 'Conflict') {
            getConflict(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    console.log(labels);
                    console.log("====================");

                    let months = getMonths(startDate, endDate);

                    console.log(incidentsData.data);

                    if (incidentsData.data == '5 Most Reported Conflict Types') {
                        let tempArray = [];
                        for (let i = 0; i < response.data.length; i++) {
                            let counter = 0;
                            for (let j = 0; j < response.data[i].data.length; j++) {
                                counter += response.data[i].data[j].count
                            }
                            tempArray.push([counter, response.data[i].type])
                        }
                        tempArray = tempArray.sort(sortFunction);
                        let datasetForMultiLineChart = [];
                        for (let k = 0; k < 5; k++) {
                            let dataForMulti = []
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].type == tempArray[k][1]) {
                                    for (let j = 0; j < months.length; j++) {
                                        dataForMulti.push(0)

                                        for (let q = 0; q < response.data[i].data.length; q++) {
                                            if (response.data[i].data[q].monthYear == months[j]) {
                                                dataForMulti[j] = response.data[i].data[q].count;
                                                break;
                                            }

                                        }
                                    }

                                    datasetForMultiLineChart.push({
                                        label: tempArray[k][1],
                                        data: dataForMulti,
                                        borderColor: colors[k],
                                        backgroundColor: colors[k],
                                        tension: 0.2
                                    })
                                }
                            }

                        }
                        console.log(datasetForMultiLineChart);
                        setchartAllData({
                            labels: months,
                            datasets: datasetForMultiLineChart,
                        });


                    } else {

                        for (let i = 0; i < response.data.length; i++) {
                            console.log(response.data[i].type);
                            if (response.data[i].type == incidentsData.data) {
                                console.log(response.data[i]);
                                for (let j = 0; j < response.data[i].data.length; j++) {
                                    console.log(response.data[i].data[j])
                                    labels.push(response.data[i].data[j].monthYear)
                                    data.push(response.data[i].data[j].count)
                                }
                                break
                            }
                        }
                        setchartAllData({
                            labels,
                            datasets: [
                                {
                                    label: incidentsData.data,
                                    data: data,
                                    borderColor: '#D87574',
                                    backgroundColor: '#D87574',
                                    tension: 0.2
                                },
                            ],
                        });
                    }

                    setIsLoading(false)
                });
        } else if (incidentsData.reportType == 'Incidents') {
            getIncidentCount(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    // setIncidents(response.data);
                    console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        labels.push(response.data[i].group)
                        data.push(response.data[i].count)
                    }

                    setchartAllData({
                        labels,
                        datasets: [
                            {
                                label: 'Incidents',
                                data: data,
                                borderColor: '#D87574',
                                backgroundColor: '#D87574',
                                tension: 0.2
                            },
                        ],
                    });

                    setIsLoading(false)
                });
        } else if (incidentsData.reportType == 'Location') {
            getLocation(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    console.log(labels);
                    console.log("====================");

                    let months = getMonths(startDate, endDate);

                    console.log(incidentsData.data);

                    if (incidentsData.data == '5 Most Reported Locations') {
                        let tempArray = [];
                        for (let i = 0; i < response.data.length; i++) {
                            let counter = 0;
                            for (let j = 0; j < response.data[i].data.length; j++) {
                                counter += response.data[i].data[j].count
                            }
                            tempArray.push([counter, response.data[i].type])
                        }
                        tempArray = tempArray.sort(sortFunction);
                        let datasetForMultiLineChart = [];
                        for (let k = 0; k < 5; k++) {
                            let dataForMulti = []
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].type == tempArray[k][1]) {
                                    for (let j = 0; j < months.length; j++) {
                                        dataForMulti.push(0)

                                        for (let q = 0; q < response.data[i].data.length; q++) {
                                            if (response.data[i].data[q].monthYear == months[j]) {
                                                dataForMulti[j] = response.data[i].data[q].count;
                                                break;
                                            }
                                        }
                                    }

                                    datasetForMultiLineChart.push({
                                        label: tempArray[k][1],
                                        data: dataForMulti,
                                        borderColor: colors[k],
                                        backgroundColor: colors[k],
                                        fill: false,
                                        tension: 0.2
                                    })
                                }
                            }
                        }
                        setchartAllData({
                            labels: months,
                            datasets: datasetForMultiLineChart,
                        });

                    } else {

                        for (let i = 0; i < response.data.length; i++) {
                            console.log(response.data[i].type);
                            if (response.data[i].type == incidentsData.data) {
                                console.log(response.data[i]);
                                for (let j = 0; j < response.data[i].data.length; j++) {
                                    console.log(response.data[i].data[j])
                                    labels.push(response.data[i].data[j].monthYear)
                                    data.push(response.data[i].data[j].count)
                                }
                                break
                            }
                        }
                        setchartAllData({
                            labels,
                            datasets: [
                                {
                                    label: incidentsData.data,
                                    data: data,
                                    borderColor: '#D87574',
                                    backgroundColor: '#D87574',
                                    tension: 0.2

                                },
                            ],
                        });
                    }
                    setIsLoading(false)
                });
        }
    }

    const mainDivPage = () => {
        setMainDiv(true);
        setchartAllData({
            labels: [],
            datasets: [
                {
                    label: '',
                    data: [],
                    backgroundColor: '#D87574',
                },
            ],
        });
    }


    const div2PDF = (e) => {

        const but = e.target;
        but.style.visibility = "hidden";
        let input = document.getElementsByClassName("div2PDF")[0];

        html2canvas(input).then(canvas => {
            let w, h;
            const img = canvas.toDataURL("image/png", w = canvas.width, h = canvas.height);
            const pdf = new jsPDF("p", "mm", "a4");
            var width = pdf.internal.pageSize.getWidth() - 20;
            pdf.addImage(
                img,
                "png",
                10,
                10,
                width,
                width * (h / w)
            );
            pdf.save("chart.pdf");
            but.style.visibility = "visible";

        }).catch(error => console.log(error));
    };

    useEffect(() => {
        setIsLoading(false)
    }, []);

    return (<>
        {isLoading ? <Loader /> : ""}

        {mainDiv ? (<>
            <div className="mx-8 mt-12 md:max-w-[60%]">
                <div className="mb-8">
                    <p className="text-2xl text-gray-800 font-semibold">Generate</p>
                </div>
                <div className="w-full my-4">
                    <label
                        htmlFor="reportType"
                        className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                    >
                        Report Type
                    </label>
                    <div className="">
                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                            <input
                                type="radio"
                                name="reportType"
                                id="incidents"
                                value="Incidents"
                                onChange={handleChangeIncidentsData}
                                hidden
                            />
                            <label
                                htmlFor="incidents"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                Incidents
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                            <input
                                type="radio"
                                name="reportType"
                                id="prejudice"
                                value="Prejudice"
                                onChange={handleChangeIncidentsData}
                                hidden
                            />
                            <label
                                htmlFor="prejudice"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                Prejudice
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                            <input
                                type="radio"
                                name="reportType"
                                id="conflict"
                                value="Conflict"
                                onChange={handleChangeIncidentsData}
                                hidden
                            />
                            <label
                                htmlFor="conflict"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                Conflict
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                            <input
                                type="radio"
                                name="reportType"
                                id="location"
                                value="Location"
                                onChange={handleChangeIncidentsData}
                                hidden
                            />
                            <label
                                htmlFor="location"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                Location
                            </label>
                        </div>
                    </div>
                </div>
                {dropdownMenuForData ? <div className="w-full my-4">
                    <label
                        htmlFor="data"
                        className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                    >
                        Data
                    </label>
                    <div className="mt-2 relative rounded-md shadow-sm">

                        <select
                            onChange={handleChangeIncidentsData}
                            // value={rowsDataClassificationTemp.name}
                            name='data'
                            className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-[1rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                            <option value="" disabled selected='true'>Please select any Data inputs below</option>
                            {optionsForData.map((e) => {
                                return (
                                    <option value={e}>
                                        {e}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div> : ''}
                <div className="w-full my-4">
                    <label
                        htmlFor="reportType"
                        className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                    >
                        Date Range
                    </label>
                    <div className="mt-2 relative rounded-md text-center md:max-w-[60%] ">
                        <div className="w-full md:w-1/2 inline-block">
                            <label
                                htmlFor="startDate"
                                className="text-[1.1rem] 3xl:text-[1.5rem] mx-6 3xl:my-4 font-semibold text-gray-600"
                            >
                                Start Date
                            </label>
                            <input
                                value={startDefaultDate}
                                onChange={handleChangeIncidentsData}
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 w-max focus:border-green-500 p-2.5"
                                id="startOfIncident"
                                name="startOfIncident"
                            />
                        </div>
                        <div className="w-full md:w-1/2 inline-block">
                            <label
                                htmlFor="reportType"
                                className="text-[1.1rem] 3xl:text-[1.5rem] mx-6 3xl:my-4 font-semibold text-gray-600"
                            >
                                End Date
                            </label>
                            <input
                                value={endDefaultDate}
                                onChange={handleChangeIncidentsData}
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 w-max focus:border-green-500 p-2.5"
                                id="endOfIncident"
                                name="endOfIncident"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full my-8">
                    <div className="border-b-2 col-span-full w-[45%] my-1 inline-block" />
                    <div className="col-span-full w-[10%] text-center inline-block justify-center"><p className="m-auto my-auto"> OR</p></div>
                    <div className="border-b-2 col-span-full w-[45%] my-1 inline-block" />
                </div>
                <div className="w-full my-8">
                    <label
                        className="text-[1.1rem] my-4 font-semibold text-gray-600"
                    >
                        Quick Search
                    </label>
                    <div className="float-right inline-block ml-6">
                        <div className="bg-white border border-black inline-flex mx-4 rounded-xl">
                            <input
                                type="radio"
                                name="quickSearch"
                                id="6_quickSearch"
                                value="6"
                                onChange={changeDates}
                                // checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                            />
                            <label
                                htmlFor="6_quickSearch"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                6 Months
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-xl">
                            <input
                                type="radio"
                                name="quickSearch"
                                id="12_quickSearch"
                                value="12"
                                onChange={changeDates}
                                // checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                            />
                            <label
                                htmlFor="12_quickSearch"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                12 Months
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-xl">
                            <input
                                type="radio"
                                name="quickSearch"
                                id="24_quickSearch"
                                value="24"
                                onChange={changeDates}
                                // checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                            />
                            <label
                                htmlFor="24_quickSearch"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                24 Months
                            </label>
                        </div>

                    </div>

                </div>
                <div>
                    <button
                        onClick={reportGenerate}
                        type="button"
                        className="inline-flex max-h-12 bottom-0 my-8 items-center px-6 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md bg-green-600 hover:bg-green-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                        <span> Generate </span>
                    </button>
                </div>
            </div>
        </>
        ) : (
            <>
                <div className="mx-8 mt-12 w-[80%]">
                    <button
                        id="prevBtn"
                        type="button"
                        onClick={mainDivPage}
                        className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
                    >
                        <span>Back </span>
                    </button>
                    <div className="div2PDF">
                        <div className="w-full mt-4">
                            <span className="text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600 mx-4">{chartTitle ? chartTitle : ''}</span>
                            <button className="bg-green-500 inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent font-semibold rounded-full shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" onClick={e => div2PDF(e)}>Export to PDF</button>
                        </div>
                        <div className="w-full border border-solid m-2 p-4">
                            <Line options={options} data={chartAllData} />
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
    )
}