import React, { useEffect, useState, createContext } from "react";
import Registration from "../component/Registration";
import { getAllUsers } from "../api/user/user.service";
import Loader from "../component/Loader";
import Error from "../component/Error";
import Swal from "sweetalert2";
import { PencilIcon } from "@heroicons/react/solid";


export const CurrentUserForReg = createContext();

function Admin() {
  const [open, setOpen] = useState(false);
  const [people, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserDataReg, setCurrentUserDataReg] = useState();
  const [errorMessage, setErrorMessage] = useState("");


  // const authorization = getCookie("authorization");
  // const handleEditBtn = ()
  const handleEditBtn = (userData) => {
    console.log(userData)
    setCurrentUserDataReg(userData)
    setOpen(true)
  }

  const getUsers = () => {
    getAllUsers()
      .then((response) => {
        console.log(response);
        setUserList(response.data);
        setIsLoading(false)
        console.log(people)
      }).catch((error) => {
        console.log(error.response.status)
        setIsLoading(false)
        if (error.response) {
          setErrorMessage(error.response)
        }
        return error.response
      });
  }

  useEffect(() => {
    console.log("get all users")
    setIsLoading(true)
    getUsers()
  }, []);

  function onRegSubmit() {
    getUsers();
    update(false)
  }

  function update(b) {
    setOpen(b);
  }
  return (
    <>
      {isLoading ? <Loader /> : ""}
      <CurrentUserForReg.Provider value={currentUserDataReg}>
        {open ? (
          <Registration onSubmit={onRegSubmit}
            onClose={() => {
              update(false);
            }}
          />
        ) : null}

        <div className="px-4 sm:px-6 lg:px-8 mt-4">
          {errorMessage && <Error text={errorMessage} />}

          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                User administration
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex justify-center m-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-[1.1rem] font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                onClick={() => {
                  setCurrentUserDataReg(null)
                  update(true);
                }}
              >
                Add New User
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                        >
                          User type
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                        >
                          Active
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {people.length > 0 ? people.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[1rem] sm:pl-6">
                            <div className="flex items-center">
                              <div className="ml-2">
                                <div className="font-medium text-gray-900">
                                  {person.first_name} {person.last_name}
                                </div>
                                <div className="text-gray-500">
                                  {person.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-[1rem] text-gray-500">
                            <div className="text-gray-900">{person.role}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-[1rem] text-gray-500">
                            {person.status == 1 ? <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">True</span> : <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-red-400">False</span>}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-[1rem] text-gray-500">
                            {person.active == true ? <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">A</span> : <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-red-400">NA</span>}
                            {/* <div className="text-gray-500 text-sm px-2">
                              {person.last_Active}
                            </div> */}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              type="button"
                              onClick={() => {
                                handleEditBtn(person);
                              }}
                              className="text-green-600 hover:text-green-900"
                            >
                              <PencilIcon
                                className="w-6 h-6"
                                aria-hidden="true"
                              />
                            </button>
                          </td>
                        </tr>
                      )) : ''}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CurrentUserForReg.Provider>
    </>
  );
}

export default Admin;
