import React, { useEffect, useState, createContext } from "react";
import { getIncident, getDocuments, getAgreementDocuments } from "../api/user/user.service";
import Loader from "../component/Loader";
import Swal from "sweetalert2";
import { Link, NavLink, useParams } from "react-router-dom";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../component/Navbar";


export const CurrentUserForReg = createContext();

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function IncidentDetails() {
    const [open, setOpen] = useState(false);
    const [incidentDetails, setIncidentDetails] = useState({});
    const [documentList, setDocumentList] = useState([])
    const [agreementPDFs, setAgreementPDFs] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { _id } = useParams();
    const navigation = [
        { id: "1", name: `One to one`, current: true },
        { id: "2", name: "Assess the risk", current: false },
        { id: "3", name: "Meeting", current: false },
        { id: "4", name: "Agreements", current: false },
        { id: "5", name: "Documents", current: false },
        { id: "6", name: "Feedback", current: false },
    ];

    const [navigationDetail, setNavigationDetail] = useState(navigation)
    const [currentBtn, setCurrentBtn] = useState(1)

    const handleButton = (e) => {
        let currentSteps = navigationDetail.map((currentStep, index) => ({
            ...currentStep,
            current: e.target.id === currentStep.id ? true : currentStep.current,
        }));
        currentSteps = currentSteps.map((currentStep, index) => ({
            ...currentStep,
            current: e.target.id !== currentStep.id ? false : currentStep.current,
        }));
        setNavigationDetail(currentSteps)
        setCurrentBtn(e.target.id)
    }

    const getIncidentDetail = (id) => {
        getIncident(id)
            .then((response) => {
                console.log("Incident Details");
                console.log(response.data);
                setIncidentDetails(response.data);
                if (response.data.incidentType == 'quick') {
                    console.log("setscasdfa");
                    setNavigationDetail([
                        { id: "1", name: `One to one`, current: true },
                        { id: "2", name: "Assess the risk", current: false },
                        // { id: "3", name: "Preparation", current: false },
                        { id: "4", name: "Agreements", current: false },
                        { id: "5", name: "Documents", current: false },
                        { id: "6", name: "Feedback", current: false },
                    ]);
                }
                setIsLoading(false)
            });
    }

    const getDocumentsList = (id) => {
        getDocuments(id)
            .then((response) => {
                console.log("documents List");
                console.log(response.data);
                setDocumentList(response.data);
                setIsLoading(false)
            });

    }

    const getAgreementPDFsforSummery = (id) => {
        getAgreementDocuments(id)
            .then((response) => {
                setAgreementPDFs(response.data);
                setIsLoading(false)
            });

    }


    const [currentUserData, setCurrentUserData] = useState({})

    const setCurrentUser = (id) => {
        console.log(incidentDetails.users)
        incidentDetails.users.map((user) => {
            if (user._id == id) {
                setCurrentUserData(user)
                console.log(user)
            }
        })
    }

    const [currentUserForFeedbackData, setCurrentUserForFeedbackData] = useState({})

    const setCurrentUserForFeedback = (id) => {
        console.log(incidentDetails.users)
        incidentDetails.users.map((user) => {
            if (user._id == id) {
                setCurrentUserForFeedbackData(user.feedback)
                console.log(user.feedback)
            }
        })
    }


    useEffect(() => {
        getDocumentsList(_id);
        getIncidentDetail(_id);
        getAgreementPDFsforSummery(_id);
    }, [_id])


    return (
        <>
            {isLoading ? <Loader /> : ""}
            <div className="px-4 sm:px-6 lg:px-8 mt-4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            Incident Summary
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                        </p>
                    </div>
                </div>

            </div>
            <Disclosure as="nav" className="bg-white shadow">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex justify-between h-16">
                                <div className="flex">
                                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                        {navigationDetail.map((item) => (
                                            <button
                                                id={item.id}
                                                onClick={handleButton}
                                                className={
                                                    item.current
                                                        ? "border-green-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-[3px] font-bold text-[1.1rem]"
                                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-[1.1rem] font-medium"
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="pt-2 pb-3 space-y-1">
                                {/* Current: "bg-green-50 border-green-500 text-green-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

                                {navigationDetail.map((item) => (
                                    <button
                                        key={item.name}
                                        onClick={handleButton}
                                        className={
                                            item.current
                                                ? "w-full text-left bg-green-50 border-green-500 text-green-700 block pl-3 pr-4 py-2 border-l-4 text-[1.1rem] font-medium"
                                                : "w-full text-left border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-[1.1rem] font-medium"
                                        }
                                    >
                                        {item.name}
                                    </button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {currentBtn == 1 ?
                <>
                    <div
                        className="flex mt-6 justify-center items-center mx-2 md:mx-4 p-2 md:px-6"
                        style={{ backgroundColor: "#ffffff" }}>
                        <div className="justify-center mt-3 mb-4 md:mb-7 w-full">
                            <p className="text-lg md:text-xl font-bold text-center mainTitle mb-4">
                                Case details
                            </p>
                            <p className="block text-[1.1rem] m-2 md:m-4 font-semibold text-gray-600">
                                Select the person to continue
                            </p>
                            <table className="min-w-[60%] border-separate [border-spacing:0.75rem]">
                                <tr className="max-w-[80%]">
                                    <td className="w-fit">
                                        <label
                                            htmlFor="description"
                                            className=" text-[1.1rem] font-semibold text-gray-600"
                                        >
                                            Persons name
                                        </label>
                                    </td>
                                    <td className="w-fit ">
                                        <select
                                            onChange={(e) => {
                                                setCurrentUser(e.target.value)
                                            }}
                                            className="bg-gray-50 border min-w-[10rem] md:min-w-[20rem] border-gray-300 text-gray-900 text-[1.1rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                        >
                                            <option value='' />
                                            {incidentDetails.users != null ? incidentDetails.users.map((e, key) => {
                                                return (
                                                    <option id={e._id} value={e._id}>
                                                        {e.firstName} {e.lastName}
                                                    </option>
                                                );
                                            }) : ''}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            what happened?
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.whatHappened : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            How did you feel before the incident occurred?
                                        </p>
                                    </td>
                                    <td>
                                        {currentUserData.data != null ? currentUserData.data.beforeIncident.map((e) => {
                                            return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                        }) : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            How did you feel during the incident occurred?
                                        </p>
                                    </td>
                                    <td>
                                        {currentUserData.data != null ? currentUserData.data.duringIncident.map((e) => {
                                            return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                        }) : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            How did you feel after the incident occurred?
                                        </p>
                                    </td>
                                    <td>
                                        {currentUserData.data != null ? currentUserData.data.afterIncident.map((e) => {
                                            return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                        }) : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Physical Location
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.physicalLocation : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Digital
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.digitalLocation : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Physical Location
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.physicalLocation : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Date of incident
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.dateOfIncident : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Time of incident
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.timeOfIncident : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Type of conflict
                                        </p>
                                    </td>
                                    <td>
                                        {currentUserData.data != null ? currentUserData.data.Conflict.map((e) => {
                                            return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                        }) : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Characteristics present in relation to the incident
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.presentCharacteristics.map((e) => {
                                                return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                            }) : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            drugs / alcohol
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-[1.1rem] font-medium text-gray-600">
                                            {currentUserData.data != null ? currentUserData.data.drugs : ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <p className=" text-[1.1rem] font-semibold text-gray-600">Solutions</p>
                                </tr>
                                <tr>
                                    {currentUserData.data != null ? currentUserData.data.solutions.map((solution) => {
                                        return (<>
                                            <td className="w-fit">
                                                {incidentDetails.users.map((user, key) => {
                                                    if (user.id == solution.name) {
                                                        return (
                                                            <>{user.firstName + ' ' + user.lastName}</>
                                                        );
                                                    }
                                                })}
                                            </td>
                                            <td className="w-fit">
                                                {solution != null ? solution.solutions.map((e) => {
                                                    return (<p className="text-[1.1rem] font-medium text-gray-600">{e} </p>)
                                                }) : ''}
                                            </td>
                                        </>
                                        )
                                    }) : ''}

                                </tr>
                            </table>
                        </div>

                    </div>
                </>
                : ''}
            {currentBtn == 2 ?
                <>
                    <div
                        className="flex mt-6 justify-center items-center mx-2 md:mx-4 p-2 md:px-6"
                        style={{ backgroundColor: "#ffffff" }}>
                        <div className="justify-center mt-3 mb-4 md:mb-7 w-full">
                            <p className="text-lg md:text-xl font-bold text-center mainTitle mb-4">
                                Support & engagement
                            </p>
                            <table className="min-w-[60%] border-separate [border-spacing:0.75rem]">
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Referrals been made
                                        </p>
                                    </td>
                                    <td>
                                        {incidentDetails.referralsMade != null ? incidentDetails.referralsMade.map((e) => {
                                            return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                        }) : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Agencies currently supporting
                                        </p>
                                    </td>
                                    <td>
                                        {incidentDetails.agenciesSupporting != null ? incidentDetails.agenciesSupporting.map((e) => {
                                            return (<p className="text-[1.1rem] font-medium text-gray-600">{e.value} </p>)
                                        }) : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Immediate risk that the conflict between the participants will escalate
                                        </p>
                                    </td>
                                    <td>
                                        {incidentDetails.particioantsEscalate ? incidentDetails.particioantsEscalate : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Participants willing to resolve this issue
                                        </p>
                                    </td>
                                    <td>
                                        {incidentDetails.willingToResolve ? incidentDetails.willingToResolve : ''}
                                    </td>
                                </tr>
                            </table>
                            {incidentDetails.incidentType == 'full' ? <table className="min-w-[60%] border-separate [border-spacing:0.75rem]">
                                <tr className="border border-solid">
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            Participant
                                        </p>
                                    </td>
                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            person willing to meet
                                        </p>
                                    </td>

                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            safe to meet with this person present
                                        </p>
                                    </td>

                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            person helpful to the resolution
                                        </p>
                                    </td>

                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            person needed for the resolution
                                        </p>
                                    </td>

                                    <td>
                                        <p className="text-[1.1rem] font-semibold text-gray-600">
                                            safe to proceed with the joint meeting
                                        </p>
                                    </td>


                                </tr>

                                {incidentDetails.classification != null ? incidentDetails.classification.map((e) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className="font-medium text-gray-900">
                                                    {incidentDetails.users.map((user, key) => {
                                                        if (user.id == e.name) {
                                                            return (
                                                                <>{user.firstName + ' ' + user.lastName}</>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                <div className="text-gray-500">
                                                    {e.classification}
                                                </div>
                                            </td>
                                            <td>
                                                <p className="text-[1.1rem] font-medium text-gray-600">{e.willingToMeet} </p>
                                            </td>
                                            <td>
                                                <p className="text-[1.1rem] font-medium text-gray-600">{e.safeToMeet} </p>
                                            </td>
                                            <td>
                                                <p className="text-[1.1rem] font-medium text-gray-600">{e.helpfulToResolution} </p>
                                            </td>
                                            <td>
                                                <p className="text-[1.1rem] font-medium text-gray-600">{e.neededForResolution} </p>
                                            </td>
                                            <td>
                                                <p className="text-[1.1rem] font-medium text-gray-600">{e.jointMeeting} </p>
                                            </td>
                                        </tr>
                                    )
                                }) : ''}
                                <tr>
                                    {/* {incidentDetails.classification != null ? incidentDetails.classification.map((e) => {
                                        return (
                                            
                                        )
                                    }) : ''} */}
                                </tr>
                            </table> : ''}
                        </div>
                    </div>
                </>
                : ''}
            {currentBtn == 3 && incidentDetails.incidentType == 'full' ?
                <>
                    <div
                        className="flex mt-6 justify-center items-center mx-2 md:mx-4 p-2 md:px-6"
                        style={{ backgroundColor: "#ffffff" }}>
                        <div className="justify-center mt-3 mb-4 md:mb-7 w-full">
                            <p className="text-lg md:text-xl font-bold text-center mainTitle mb-4">
                                Meeting
                            </p>
                            <p className="text-[1.1rem] font-semibold text-gray-600 mb-2">
                                Present
                            </p>
                            <ul className=" mb-2 list-disc ml-10">
                                {incidentDetails.speakingOrder != null ? incidentDetails.speakingOrder.map((e, key) => {
                                    if (e.present) {
                                        return (
                                            <li>
                                                {incidentDetails.users.map((user, key) => {
                                                    if (user.id == e.name) {
                                                        return (
                                                            <>{user.firstName + ' ' + user.lastName}</>
                                                        );
                                                    }

                                                })}
                                            </li>
                                        );
                                    }
                                }) : ''}
                            </ul>
                            <p className="text-[1.1rem] font-semibold text-gray-600 mb-2">
                                Absent
                            </p>
                            <ul className=" mb-2 list-disc ml-10">
                                {incidentDetails.speakingOrder != null ? incidentDetails.speakingOrder.map((e, key) => {
                                    if (!e.present) {
                                        return (
                                            <li>
                                                {incidentDetails.users.map((user, key) => {
                                                    if (user.id == e.name) {
                                                        return (
                                                            <>{user.firstName + ' ' + user.lastName}</>
                                                        );
                                                    }

                                                })}
                                            </li>
                                        );
                                    }
                                }) : ''}
                            </ul>

                            <table className="min-w-[60%] border-separate [border-spacing:0.75rem]">
                                <tr>
                                    <p className=" text-[1.1rem] font-semibold text-gray-600">Agreeing Solutions</p>
                                </tr>
                                <tr>
                                    {incidentDetails.agreegingSolution != null ? incidentDetails.agreegingSolution.map((solution) => {
                                        return (<>
                                            <td className="w-fit">
                                                <b>{incidentDetails.users.map((user, key) => {
                                                    if (user.id == solution.name) {
                                                        return (
                                                            <>{user.firstName + ' ' + user.lastName}</>
                                                        );
                                                    }
                                                })}</b> and
                                                <b> {incidentDetails.users.map((user, key) => {
                                                    if (user.id == solution.name2) {
                                                        return (
                                                            <>{user.firstName + ' ' + user.lastName}</>
                                                        );
                                                    }
                                                })}</b>
                                            </td>
                                            <td className="w-fit">
                                                {solution != null ? solution.solutions.map((e) => {
                                                    return (<p className="text-[1.1rem] font-medium text-gray-600">{e} </p>)
                                                }) : ''}
                                            </td>
                                        </>
                                        )
                                    }) : ''}

                                </tr>
                            </table>

                        </div>
                    </div>
                </>
                : ''}
            {currentBtn == 4 ?
                <>
                    <div
                        className="flex mt-6 justify-center items-center mx-2 md:mx-4 p-2 md:px-6"
                        style={{ backgroundColor: "#ffffff" }}>
                        <div className="justify-center mt-3 mb-4 md:mb-7 w-full">
                            <p className="text-lg md:text-xl font-bold text-center mainTitle mb-4">
                                Agreements List
                            </p>
                            <ul className=" mb-2 list-disc ml-10">
                                {agreementPDFs != null ? agreementPDFs.map((e, key) => {
                                    if (e.fileName) {
                                        return (
                                            <li>
                                                <a style={{ display: "table-cell" }} target="_blank" href={e.url}>{e.fileName}</a>
                                            </li>
                                        )
                                    }
                                }) : ''}
                            </ul>

                        </div>
                    </div>
                </>
                : ''}

            {currentBtn == 5 ?
                <>
                    <div
                        className="flex mt-6 justify-center items-center mx-2 md:mx-4 p-2 md:px-6"
                        style={{ backgroundColor: "#ffffff" }}>
                        <div className="justify-center mt-3 mb-4 md:mb-7 w-full">
                            <p className="text-lg md:text-xl font-bold text-center mainTitle mb-4">
                                Document List
                            </p>
                            <ul className=" mb-2 list-disc ml-10">
                                {documentList != null ? documentList.map((e, key) => {
                                    if (e.name) {
                                        return (
                                            <li>
                                                <a style={{ display: "table-cell" }} target="_blank" href={e.url}>{e.name}</a>
                                            </li>
                                        )
                                    }
                                }) : ''}
                            </ul>

                        </div>
                    </div>
                </>
                : ''}
            {currentBtn == 6 ?
                <>
                    <div
                        className="flex mt-6 justify-center items-center mx-2 md:mx-4 p-2 md:px-6"
                        style={{ backgroundColor: "#ffffff" }}>
                        <div className="justify-center mt-3 mb-4 md:mb-7 w-full">
                            <p className="text-lg md:text-xl font-bold text-center mainTitle mb-4">
                                Feedback
                            </p>
                            <p className="block text-[1.1rem] m-2 md:m-4 font-semibold text-gray-600">
                                Select the person to continue
                            </p>
                            <table className="min-w-[60%] border-separate [border-spacing:0.75rem]">
                                <tr className="max-w-[80%]">
                                    <td className="w-fit">
                                        <label
                                            htmlFor="description"
                                            className=" text-[1.1rem] font-semibold text-gray-600"
                                        >
                                            Persons name
                                        </label>
                                    </td>
                                    <td className="w-fit ">
                                        <select
                                            onChange={(e) => {
                                                setCurrentUserForFeedback(e.target.value)
                                            }}
                                            className="bg-gray-50 border min-w-[10rem] md:min-w-[20rem] border-gray-300 text-gray-900 text-[1.1rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                        >
                                            <option value='' />
                                            {incidentDetails.users != null ? incidentDetails.users.map((e, key) => {
                                                return (
                                                    <option id={e._id} value={e._id}>
                                                        {e.firstName} {e.lastName}
                                                    </option>
                                                );
                                            }) : ''}
                                        </select>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Did you volunteer to take part in the restorative process?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.partInRestoration : ''}
                                    </p></td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Had you heard of conflict resolution before using the service?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.conflictResolution : ''}
                                    </p></td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            How well did the facilitator explain to you what to expect from participating in the conflict resolution process?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.expectParticipating : ''}
                                    </p></td>
                                    {/* <div className="float-right"> */}

                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Did you have a one on one meeting with the facilitator?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.meetingWithFacilitatior : ''}
                                    </p></td>
                                </tr>

                            </table>
                            <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                            <p className="text-[1.1rem] font-semibold text-gray-600">
                                During the interviews, rate how well you think the Facilitator dealt with each of the following sections:
                            </p>
                            <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                            <table style={{
                                borderCollapse: 'separate',
                                borderSpacing: '0px 1rem',
                                "width": "100%"
                            }}>
                                {/* <tr className="w-full">
                                    <td className="w-[50%]"></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600 "> Very well</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600">Well</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600">Fair / Satisfactory</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600"> Poor</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600">Very Poor</span></td>

                                </tr> */}
                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            What happened
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.whatHappened : ''}
                                    </p></td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Feelings and emotions
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.feeling : ''}
                                    </p></td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Who else was affected
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.wasAffected : ''}
                                    </p></td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            What solutions could resolve the situation
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.resolveSituation : ''}
                                    </p></td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            What you needed in order to move on
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.orderToMove : ''}
                                    </p></td>
                                </tr>

                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            How well informed did you feel about meeting with the other party?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.meetingOtherParty : ''}
                                    </p></td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            How safe did you feel meeting with the other party?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.safeMeetingParty : ''}
                                    </p></td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Do you feel safe now with the other people involved?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.safeMeetingParty : ''}
                                    </p></td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Did you reach a satisfactory conclusion?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.reachSatisfactoryConclusion : ''}
                                    </p></td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Overall are you satisfied with how the Facilitator managed the process?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    <td><p className=" text-[1.1rem] font-medium text-gray-600">
                                        {currentUserForFeedbackData != null ? currentUserForFeedbackData.overallSatisfied : ''}
                                    </p></td>
                                </tr>

                            </table>
                        </div>

                    </div>
                </>
                : ''}

        </>
    );
}

export default IncidentDetails;
