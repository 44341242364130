import React, { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import auth from "../api/auth/auth.service";
import logo from "../logos/logoNavbar.png";
import { getCurrentUser } from "../api/user/user.service";


const navigation = [
  { name: "Allocate Case", href: "/allocate_incident", current: true, adminOnly: true },
  { name: "My Cases", href: "/my_incidents", current: false, adminOnly: false },
  { name: "Dashboard", href: "/dashboard", current: false, adminOnly: false },
  { name: "Admin", href: "/admin", current: false, adminOnly: true },
];

const userNavigation = [{ name: "Sign out", href: "/login" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {

  const [state, setState] = useState({ name: "", email: "", role: '' });
  const logout = () => {
    auth.logout();
  };
  React.useEffect(() => {
    getCurrentUser()
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        console.log(response);
        setState({
          name: response.first_name + " " + response.last_name,
          email: response.email,
          role: response.role
        });
        console.log(response.role)
      });

  }, []);

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white shadow-md">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-16 w-full" src={logo} alt="Logo" />
                      {/* <p className='text-black font-extrabold'>Company Name</p> */}
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.filter(({ adminOnly }) => state.role === 'Administrator' || !adminOnly).map((item) => (
                          <NavLink
                            key={item.name}
                            aria-current={item.current ? "page" : undefined}
                            to={item.href}
                            className={({ isActive }) => {
                              return isActive
                                ? "bg-[#008453] text-black px-3 py-2 rounded-md text-[0.95rem] font-semibold"
                                : "text-black hover:bg-[#00dc6f] hover:bg-opacity-75 px-3 py-2 rounded-md text-[0.95rem] font-semibold";
                            }
                            }
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-white rounded-full flex items-center px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] hover:bg-[#00dc6f] hover:bg-opacity-75 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <p className="p-1 text-black text-[0.9rem] font-semibold">
                              {state.name}
                            </p>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <NavLink
                                    onClick={logout}
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-[#008453] inline-flex items-center justify-center p-2 rounded-md text-black hover:text-black hover:bg-[#00dc6f] hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#008453] focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-[#008453] text-black"
                          : "text-black hover:bg-[#00dc6f] hover:bg-opacity-75",
                        "block px-3 py-2 rounded-md text-[1.1rem] font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-[#008453]">
                  <div className="flex items-center px-5">
                    <div className="ml-3">
                      <div className="text-[1.1rem] font-medium text-black">
                        {state.name}
                      </div>
                      <div className="text-[1rem] font-medium text-indigo-300">
                        {state.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        onClick={logout}
                        className="block px-3 py-2 rounded-md text-[1.1rem] font-medium text-black hover:bg-[#00dc6f] hover:bg-opacity-75"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
