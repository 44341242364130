import { useEffect, useState } from "react";
import { getStats, getStatsOfCases } from "../../api/user/user.service";
import Loader from "../../component/Loader";

export default function Summary() {
    // const incident = useContext(incidents);
    console.log("incidents");
    // console.log(incident);
    const [incidents, setIncidents] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState([])
    // const [stats, setStats] = useState({ active: '', paused: "", escalated: '', closed: '', unallocated: '' })

    const allIncidents = () => {
        getStats()
            .then((response) => {
                console.log(response);
                setStats(response.data)
                setIsLoading(false)
            });
        getStatsOfCases()
            .then((response) => {
                console.log(response);
                setIncidents(response.data);
                setIsLoading(false)
            });

    }

    useEffect(() => {
        console.log("get all users")
        setIsLoading(true)
        allIncidents();

    }, []);
    return (
        <>
            {isLoading ? <Loader /> : ""}
            <div className="mx-8 mt-12 md:w-[60%]">
                <div className="mb-4">
                    <p className="text-2xl text-gray-800 font-semibold">Status of Cases</p>
                </div>
                <div className="grid grid-cols-1  gap-6 mb-12 md:grid-cols-5">
                    <div className="w-full max-w-20 px-4 py-5 bg-white rounded-lg border-2 shadow border-green-500">
                        <div className="text-base font-medium text-gray-500 truncate">
                            Active
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            {stats?.find((obj) => obj?._id == 'active')?.count || 0}
                        </div>
                    </div>
                    <div className="w-full px-4 py-5 bg-white rounded-lg border-2 shadow border-purple-500">
                        <div className="text-base font-medium text-gray-500 truncate">
                            Paused
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            {stats?.find((obj) => obj?._id == 'pause')?.count || 0}
                        </div>
                    </div>
                    <div className="w-full px-4 py-5 bg-white rounded-lg border-2 shadow border-orange-500">
                        <div className="text-base font-medium text-gray-500 truncate">
                            Escalated
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            {stats?.find((obj) => obj?._id == 'escalated')?.count || 0}

                        </div>
                    </div>
                    <div className="w-full px-4 py-5 bg-white rounded-lg border-2 shadow border-blue-500">
                        <div className="text-base font-medium text-gray-500 truncate">
                            Unallocated
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            {stats?.find((obj) => obj?._id == 'unallocated')?.count || 0}

                        </div>
                    </div>
                    <div className="w-full px-4 py-5 bg-white rounded-lg border-2 shadow border-gray-500">
                        <div className="text-base font-medium text-gray-500 truncate">
                            Closed
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            {stats?.find((obj) => obj?._id == 'closed')?.count || 0}
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <p className="text-2xl text-gray-800  font-semibold">Cases per Practitioner</p>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                                            >
                                                Practitioner
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                                            >
                                                Assigned Cases
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                                            >
                                                Active Cases
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {incidents.length > 0 ? incidents.map((person) => (
                                            <tr key={person._id._id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[1rem] sm:pl-6">
                                                    <div className="flex items-center">
                                                        <div className="ml-2">
                                                            <div className="font-medium text-gray-900">
                                                                {person._id.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-[1rem] text-gray-500">
                                                    <div className="text-gray-900">{person.total}</div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-[1rem] text-gray-500">
                                                    <div className="text-gray-900">{person.active}</div>
                                                </td>
                                            </tr>
                                        )) : ''}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

