import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../baseurl';
import authHeader from '../auth/auth.headers';
import { getCookie } from '../../utils/cookie/cookie';

const URL = API_URL;

export async function getCurrentUser() {
    const authorization = getCookie('authorization')
    // console.log(authorization);
    const res = await axios.get(URL + '/user/me', {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization,
        },
    });
    console.log(res.data);
    return res;
}


export async function loginReq(data) {
    const res = await axios.post(URL + '/auth/login', data, {
        headers: {
            "Content-Type": "application/json"
        },
    });
    return res;
}

export const addNewUserReq = (data) => {
    const authorization = getCookie('authorization')
    return axios.post(URL + '/user/add', data, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const updateUserReq = (data) => {
    const authorization = getCookie('authorization')
    return axios.put(URL + '/user/update', data, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const addNewCase = (data) => {
    const authorization = getCookie('authorization')
    return axios.post(URL + '/incident/newCase', data, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const getAllUsers = () => {
    const authorization = getCookie('authorization')
    return axios.get(URL + '/user/allusers', {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const allIncidents = () => {
    const authorization = getCookie('authorization')
    return axios.get(URL + '/incident/allincident', {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const allAllocatedIncidents = () => {
    const authorization = getCookie('authorization')
    return axios.get(URL + '/incident/allincident/allocated', {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const getIncident = (id) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + '/incident/getincident/' + id, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}


export const updateIncident = (data) => {
    const authorization = getCookie('authorization')
    return axios.put(URL + '/incident/updateincident', data, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const passwordSet = (id, token, data) => {
    return axios.post(URL + `/auth/setpassword/${id}/${token}`, data, {
        headers: {
            "Content-Type": "application/json"
        },
    });
}

export const agreementCreate = async data => {
    const authorization = getCookie('authorization')
    return await axios.post(URL + `/incident/${data.case_id}/agreement`, data, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const getAgreementDocuments = (case_id) => {
    console.log("get documents");
    const authorization = getCookie('authorization')
    return axios.get(URL + `/incident/${case_id}/agreement`, {
        headers: {
            "Content-Type": "multipart/form-data",
            "authorization": authorization
        },
    });
}

export const uploadDocuments = (case_id, obj) => {
    console.log("upload documents");
    const authorization = getCookie('authorization')
    return axios.post(URL + `/incident/${case_id}/documents`, obj, {
        headers: {
            "Content-Type": "multipart/form-data",
            "authorization": authorization
        },
    });
}

export const getDocuments = (case_id) => {
    console.log("get documents");
    const authorization = getCookie('authorization')
    return axios.get(URL + `/incident/${case_id}/documents`, {
        headers: {
            "Content-Type": "multipart/form-data",
            "authorization": authorization
        },
    });
}


export const feedbackForm = async data => {
    return await axios.post(URL + `/feedback`, data, {
        headers: {
            "Content-Type": "application/json",
        },
    });
}

export const sendEmail = (route, data) => {
    const authorization = getCookie('authorization')
    return axios.post(URL + `/email/` + route, data, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const getIncidentCount = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/no-of-incident`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getStats = () => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const getStatsOfCases = () => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/user-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
    });
}

export const getUserStatsByDate = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/user-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getPresentCharacteristicsStats = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/present-characteristics-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getConflictStats = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/conflict-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getLocationStats = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/location-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getAgreementsStatsByMonth = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/agreement-stats-months`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getEmotionsBefore = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/before-incident-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getEmotionsDuring = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/during-incident-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getEmotionsAfter = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/after-incident-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getSolutions = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/solutions-trends`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getPrejudice = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/prejudice-trends`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getConflict = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/conflict-trends`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}

export const getLocation = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/location-trends`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}


export const getAgreementStats = (start_date, end_date) => {
    const authorization = getCookie('authorization')
    return axios.get(URL + `/dashboard/agreement-stats`, {
        headers: {
            "Content-Type": "application/json",
            "authorization": authorization
        },
        params: {
            start_date,
            end_date
        }
    });
}



