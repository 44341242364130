import { ClipboardListIcon, PencilAltIcon, UploadIcon } from "@heroicons/react/solid";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { agreementCreate, uploadDocuments } from "../api/user/user.service";
import { DateUtils } from "../utils/date/date";
import FilterComponent from "./FilterComponent";

const DatatableForIncidents = props => {
    var navigate = useNavigate();
    const hiddenFileInput = React.useRef(null);

    const [fileUploadDiv, setFileUploadDiv] = useState(false);


    const [file, setFile] = useState();
    const [id, setId] = useState();
    const popupFileUploadDivCloseModel = () => setFileUploadDiv(false);

    const popupFileUploadDiv = (id) => {
        setId(id)
        setFileUploadDiv(true)
    };

    const handleChange = (event) => {
        setFile(event.target.files)
    };

    // function for PauseButton popup
    const popupNotificationPause = (e, reason) => {
        let msg = `Reason for pausing the case : ${reason.reason} </br> Is there an immediate risk that the conflict between the participants will escalate? ${reason.particioantsEscalate} </br> Are the participants keen to resolve this issue? ${reason.keenYoResolve}`
        Swal.fire({
            html: msg,
            showConfirmButton: false,
        })
    }
    // function for ActiveButton popup
    const popupNotificationActive = (e) => {
        // Swal.fire({
        //     text: "msg",
        //     showConfirmButton: false,
        //   })

    }
    // function for TriageButton popup
    const popupNotificationTriage = (e) => {
        Swal.fire({
            text: "Triage",
            showConfirmButton: false,
        })

    }
    // function for EscalatedButton popup
    const popupNotificationEscalated = (e, reason) => {
        let msg = '';
        if (reason) {
            msg = `Escalate case to : ${reason.escalateTo} </br> Escalated this case for safety reasons? ${reason.forSafety} </br> Could the needs of the participants be met without the input of the party who no longer wants to participate? ${reason.noParticipants} </br> Would a larger meeting, including additional people, assist the process? ${reason.assistProcess}</br>`
            if (reason.additional.length > 0) {
                for (let i = 0; i < reason.additional.length; i++) {
                    msg += `Name : ${reason.additional[i].firstName} ${reason.additional[i].lastName} </br> Email : ${reason.additional[i].email}</br>`
                }
            }
        }
        Swal.fire({
            html: msg,
            showConfirmButton: false,
        })
    }

    // fuunciton for pause and active case popup 
    // const pauseAndActiveCase = (e) => {
    //     console.log('you need to add details about this in server and popup the next modfules about that');
    //     if(e.status){
    //         console.log("you can passed by that feature and passed it by already declaired variable");
    //     }
    // }

    const handleSubmit = event => {
        event.preventDefault()
        const formData = new FormData();
        var imagefile = document.querySelector('#uploadFiles');
        for (let i = 0; i < imagefile.files.length; i++) {
            formData.append("files", imagefile.files[0]);
        }
        uploadDocuments(id, formData)
            .then((response) => {
                console.log(response.data);
                popupFileUploadDivCloseModel()
                return response.data;
            })
    };


    const columns = [
        {
            name: "Reference",
            selector: "_id",
            sortable: true,
            cell: row =>
                <>
                    <div className="flex items-center">
                        <div className="ml-2">
                            <div className="font-medium text-gray-900">
                                {row._id}
                            </div>
                        </div>
                    </div>
                </>
        },
        {
            name: "Date registered",
            selector: "createdAt",
            sortable: true,
            hide: "sm",
            cell: row =>
                <>
                    <div className="text-gray-900">
                        {DateUtils.toDateString(row.createdAt)}
                    </div>
                </>,
        },
        {
            name: "Description",
            selector: "description",
            sortable: true,
            grow: 2
        },
        {
            name: "Status",
            selector: "formStatus",
            cell: (row) => {
                if (row.formStatus == 'active') {
                    return <button type="button" onClick={() => { popupNotificationActive(row.formStatus, row.formStatusReason) }}> <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">Active</span></button>
                } else if (row.formStatus == 'pause') {
                    return <button type="button" onClick={() => { popupNotificationPause(row.formStatus, row.formStatusReason) }}><span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-red-400">Pause</span></button>
                } else if (row.formStatus == 'triage') {
                    return <button type="button" onClick={() => { popupNotificationTriage(row.formStatus, row.formStatusReason) }}><span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-yellow-400">Triage</span></button>
                } else if (row.formStatus == 'escalated') {
                    return <button type="button" onClick={() => { popupNotificationEscalated(row.formStatus, row.formStatusReason) }}><span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-orange-400">Escalated</span></button>
                } else if (row.formStatus == 'closed') {
                    return <button type="button" ><span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-gray-400">Closed</span></button>
                }
            }
            ,
            sortable: true,
            hide: "md"
        },
        {
            name: "Edit",
            button: true,
            cell: row =>
                <>
                    <button
                        type="button"
                        onClick={() => {
                            if (row.incidentType == 'full') {
                                navigate("/fullcase/" + row._id);
                            } else {
                                navigate("/quickcase/" + row._id);
                            }
                        }}
                        className="text-green-600 m-2 hover:text-green-900"
                    >
                        <PencilAltIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                        />
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            navigate("/incidentDetails/" + row._id);
                        }}
                        className="text-green-600 m-2 hover:text-green-900"
                    >
                        <ClipboardListIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                        />
                    </button>
                    <button
                        type="button"
                        onClick={() => { popupFileUploadDiv(row._id) }}
                        className="text-green-600 m-2 hover:text-green-900"
                    >
                        <UploadIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                        />

                    </button>
                </>
        },
        {
            name: "Feedback",
            selector: "feedbackStatus",
            sortable: true,
            hide: "md"
        },

    ];

    const [filterText, setFilterText] = React.useState("");
    const [filterTextDropdown, setFilterTextDropdown] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    const filteredItems = props.data.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
                <div className='col-md-6 flex flex-end'>
                    <select
                        name='inquiry-filter'
                        onChange={e => setFilterText(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                    >
                        <option value=''>All</option>
                        <option value='active'>Active</option>
                        <option value='pause'>Pause</option>
                        <option value='triage'>Triage</option>
                    </select>
                </div>
            </>
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                title="Contact List"
                columns={columns}
                data={filteredItems}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}

            />
            {fileUploadDiv ? (
                <>
                    <div
                        id="overlay"
                        className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
                    />
                    <div
                        id="dialogForPerson"
                        className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
                    >
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="justify-center mt-3 mb-4 md:mb-7">
                                    <p className="text-xl md:text-2xl xl:text-2xl 3xl:text-2xl font-bold text-center ">
                                        Upload Documents
                                    </p>
                                    <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                                </div>
                                <input type="file" id="uploadFiles" onChange={handleChange} multiple />
                                <button type="submit"
                                    className="px-5 py-2 m-2 bg-green-600 hover:bg-green-800 text-white cursor-pointer rounded-md">Upload</button>
                                <button
                                    onClick={popupFileUploadDivCloseModel}
                                    id="closePerson"
                                    type="button"
                                    className="px-5 py-2 m-2 bg-red-500 hover:bg-red-700 text-white cursor-pointer rounded-md"
                                >
                                    Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default DatatableForIncidents;
